import React, { useState, useEffect } from 'react'
import './homepage-images-slider.scss'
import TransparentButton from '../buttons/TransparentButton/transparent-button'

import { GatsbyImage, getImage } from 'gatsby-plugin-image'


const HomepageImagesSlider = (props) => {


    const [ imageIndex, setImageIndex ] = useState(0);


    useEffect(() => {
        // const images = [...document.querySelectorAll('.image-slider-container img')]
        const images = [...document.querySelectorAll('.slider-image')]
        
        if(imageIndex === 0) {
            images[imageIndex].classList.add('animate');
        } else if (imageIndex > 0 && imageIndex < images.length) {
            images[imageIndex - 1].classList.remove('animate');
            images[imageIndex].classList.add('animate');
        } else {
            images[imageIndex - 1].classList.remove('animate');
            setImageIndex(0)
        }

        const interval = setInterval(() => {
            setImageIndex(imageIndex => imageIndex + 1)
        }, 5000)
        return() => clearInterval(interval)

    }, [imageIndex])
    
    return (
        <section className="homepage-intro-section">
        <div className="image-slider-container">
            {props.images.map((image, i) => {
                return (
                    <div key={i} className="slider-image">
                        <GatsbyImage className="image-from-gatsby" image={getImage(image)} alt={image.title} objectFit="cover" />
                    </div>
                )
            })}

        </div>
        <div className="slider-overlay-container">
            <h1>{props.heading}</h1>
            <p>{props.description}</p>
            <div className='hompage-btn-section'>
                <TransparentButton
                    href="properties"
                    buttonText="Find a Property"
                    isPrimary
                ></TransparentButton>
                <TransparentButton
                    href="contact-us"
                    buttonText="Contact Us"
                ></TransparentButton>
            </div>
        </div>
        </section>
    )
}

export default HomepageImagesSlider