import React from 'react';
import LayoutHomepage from '../components/LayoutHomepage/layout-homepage'

import HomepageImagesSlider from '../components/HomepageImagesSlider/homepage-images-slider'; 
import HomeInTheNews from '../components/HomeInTheNews/home-in-the-news';
import TestimonialsSmall from '../components/TestiomonialsSmall/testimonials-small'

import { graphql } from 'gatsby'
import SeoHelmet from '../components/SeoHelmet/seo-helmet.js'
import HomepageMainHeading from '../components/HomePageMainHeading/homepage-main-heading';
import FeaturedTabs from '../components/FeaturedTabs/featured-tabs';
import NewProjects from '../components/NewProjects/NewProjects.js';
import Services from '../components/our-services/our-services';
import ContentBlock from '../components/ContentBlock/ContentBlock';


export const query = graphql`
    {
        seo: allContentfulHomepageElements(
          filter: {title: {ne: "HomePage Element Reference - DO NOT DELETE"}}
          ) {
            edges {
              node {
                seo {
                  metaTitle
                  metaDescription {
                    metaDescription
                  }
                }
              }
            }
          }
        homepage: allContentfulHomepageElements(
          filter: {title: {ne: "HomePage Element Reference - DO NOT DELETE"}}
        ) {
          edges {
            node {
              homeHeading
              homeMainHeading
              homeMainDescription
              homeContentBlock {
                raw
              }
            }
          }
        }
        sliderImages: allContentfulHomepageElements(
          filter: {title: {ne: "HomePage Element Reference - DO NOT DELETE"}}
          ) {
            edges {
              node {
                homepageCarouselImages {
                  gatsbyImageData (
                    width: 1900
                    placeholder: BLURRED
                    cropFocus: BOTTOM
                    aspectRatio:1.7
                  )
                  title
                }
              }
            }
          }
    }
`

const IndexPage = ({data: {sliderImages, seo, homepage}}) => (
  
    <LayoutHomepage> 

        <SeoHelmet
          metaTitle = {seo.edges[0].node.seo.metaTitle}
          metaDescription = {seo.edges[0].node.seo.metaDescription.metaDescription}
            ogimage = {sliderImages.edges[0]?.node?.homepageCarouselImages[0]}
        ></SeoHelmet>

        <HomepageImagesSlider
            images = {sliderImages.edges[0].node.homepageCarouselImages}
            heading = {homepage.edges[0].node.homeMainHeading}
            description = {homepage.edges[0].node.homeMainDescription}
        ></HomepageImagesSlider>

        {/* <HomepageMainHeading
          homeHeading = {homepage.edges[0].node.homeHeading}
        ></HomepageMainHeading> */}
        <NewProjects></NewProjects>
        <FeaturedTabs></FeaturedTabs> 
        <Services></Services>
        <ContentBlock content={homepage.edges[0].node.homeContentBlock}></ContentBlock>
        <HomeInTheNews></HomeInTheNews>
        <TestimonialsSmall></TestimonialsSmall>
    </LayoutHomepage>
)

export default IndexPage